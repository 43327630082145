import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import farmsReducer from "./farms";
import poolsReducer from "./pools";
import pricesReducer from "./prices";
import profileReducer from "./profile";
import teamsReducer from "./teams";
import achievementsReducer from "./achievements";
import blockReducer from "./block";
import collectiblesReducer from "./collectibles";
import referralsReducer from "./referrals";
import statisticsReducer from "./statistics";
import lotteryReducer from "./lottery";

const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: {
    achievements: achievementsReducer,
    block: blockReducer,
    farms: farmsReducer,
    pools: poolsReducer,
    prices: pricesReducer,
    profile: profileReducer,
    teams: teamsReducer,
    collectibles: collectiblesReducer,
    referrals: referralsReducer,
    statistics: statisticsReducer,
    lottery: lotteryReducer,
  },
});

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
