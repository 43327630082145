import React, { useEffect, lazy } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { ResetCSS } from "@bds-libs/uikit";
import BigNumber from "bignumber.js";
import useEagerConnect from "hooks/useEagerConnect";

import {
  useFetchPriceList,
  useFetchProfile,
  useFetchPublicData,
  useSaveReferrer,
} from "state/hooks";
import GlobalStyle from "./style/Global";
import Menu from "./components/Menu";
import SuspenseWithChunkError from "./components/SuspenseWithChunkError";
import ToastListener from "./components/ToastListener";
import PageLoader from "./components/PageLoader";
import EasterEgg from "./components/EasterEgg";
import history from "./routerHistory";

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Home = lazy(() => import("./views/HomeBDS"));
const Farms = lazy(() => import("./views/FarmsBDS"));
const Lottery = lazy(() => import("./views/Lottery"));
const NotFound = lazy(() => import("./views/NotFound"));
const Referrals = lazy(() => import("./views/Referrals"));

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

const App: React.FC = () => {
  // Monkey patch warn() because of web3 flood
  // To be removed when web3 1.3.5 is released
  useEffect(() => {
    console.warn = () => null;
  }, []);

  useEagerConnect();
  useFetchPublicData();
  useFetchProfile();
  useFetchPriceList();
  useSaveReferrer();
  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/farms">
              <Farms />
            </Route>
            <Route path="/farms">
              <Farms />
            </Route>
            <Route path="/pools">
              <Farms isPool={true} />
            </Route>
            <Route path="/lottery">
              <Lottery />
            </Route>
            <Route path="/referrals">
              <Referrals />
            </Route>
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      <EasterEgg iterations={2} />
      <ToastListener />
    </Router>
  );
};

export default React.memo(App);
