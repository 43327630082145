import { Ifo, Token } from "./types";
import farms from "./farms";
import tokens from "./tokens";

const becoKaiLpToken: Token = {
  symbol: farms[0].lpSymbol,
  address: farms[0].lpAddresses,
  decimals: 18,
};

const ifos: Ifo[] = [
  {
    id: "sleepearn",
    address: "0xEDc3377Ec481eD5704641f0fB0E6CB1F6ea68aBf",
    isActive: false,
    name: "SleepEarn (SEN)",
    poolBasic: {
      saleAmount: "255,000 SEN",
      raiseAmount: "$127,500",
      cakeToBurn: "$63,750",
      distributionRatio: 0.3,
    },
    poolUnlimited: {
      saleAmount: "595,000 SEN",
      raiseAmount: "$297,500",
      cakeToBurn: "$148,750",
      distributionRatio: 0.7,
    },
    currency: becoKaiLpToken,
    token: tokens.sen,
    releaseBlockNumber: 3092201,
    campaignId: "111110000",
    articleUrl:
      "https://becoswap.medium.com/becoswap-joins-hand-with-sleepearn-finance-sen-and-host-their-ifo-on-becoswap-83195931b8a2",
    tokenOfferingPrice: 0.5,
    version: 2,
  },

  {
    id: "trustpay",
    address: "0xD7169252fB621421203817E50F7C534F3daddF3C",
    isActive: true,
    name: "Trustpay (TPH)",
    poolBasic: {
      saleAmount: "1,500,000 TPH",
      raiseAmount: "$112,500",
      cakeToBurn: "$56,250",
      distributionRatio: 0.3,
    },
    poolUnlimited: {
      saleAmount: "3,500,000 TPH",
      raiseAmount: "$262,500",
      cakeToBurn: "$131,250",
      distributionRatio: 0.7,
    },
    currency: becoKaiLpToken,
    token: tokens.tph,
    releaseBlockNumber: 3316700,
    campaignId: "111110000",
    articleUrl:
      "https://becoswap.medium.com/trustpay-tph-ifo-to-be-hosted-on-becoswap-567a79ab8ec",
    tokenOfferingPrice: 0.075,
    version: 2,
  },
];

export default ifos;
