import { createGlobalStyle } from "styled-components";
import { PancakeTheme } from "@bds-libs/uikit/dist/theme";

declare module "styled-components" {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Roboto', sans-serif;
  }
  body {
    background-image: url('/images/banner_bds.jpg');
    background-color: ${({ theme }) => theme.colors.background};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    img {
      height: auto;
      max-width: 100%;
    }
  }
`;

export default GlobalStyle;
