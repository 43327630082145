/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PriceApiResponse, PriceApiThunk, PriceState } from "state/types";

const initialState: PriceState = {
  isLoading: false,
  lastUpdated: null,
  data: {},
  dataKAI: {},
};

// Thunks
export const fetchPrices = createAsyncThunk<PriceApiThunk>(
  "prices/fetch",
  async () => {
    const response = await fetch(
      "https://bds-kai-prices.netlify.app/api/hello"
    );
    const data = (await response.json()) as PriceApiResponse;
    data.data = {
      ...data.data,
      // @todo hardcode price
      ["0x7C0073F6285A89c7cf1e197e5D22554ff8726334"]:
        data.data["0x72b7181bd4a0B67Ca7dF2c7778D8f70455DC735b"],
    };
    // Return normalized token names
    const prices = {
      updated_at: data.updated_at,
      dataKAI: Object.keys(data.data).reduce((accum, token) => {
        return {
          ...accum,
          [token.toLowerCase()]: parseFloat(data.data[token].price_KAI),
        };
      }, {}),
      data: Object.keys(data.data).reduce((accum, token) => {
        return {
          ...accum,
          [token.toLowerCase()]: parseFloat(data.data[token].price),
        };
      }, {}),
    };

    return prices;
  }
);

export const pricesSlice = createSlice({
  name: "prices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPrices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchPrices.fulfilled,
      (state, action: PayloadAction<PriceApiThunk>) => {
        state.isLoading = false;
        state.lastUpdated = action.payload.updated_at;
        state.data = action.payload.data;
        state.dataKAI = action.payload.dataKAI;
      }
    );
  },
});

export default pricesSlice.reducer;
