import { InjectedConnector } from "@web3-react/injected-connector";
import { KaiConnector } from "@becoswap-libs/kai-connector";
import { ConnectorNames } from "@bds-libs/uikit";
import { ethers } from "ethers";

const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10);

const injected = new InjectedConnector({ supportedChainIds: [chainId] });

const kaiConnector = new KaiConnector({ supportedChainIds: [chainId] });

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.BSC]: injected,
  [ConnectorNames.KAI]: kaiConnector,
};

const POLLING_INTERVAL = 12000;

export const getLibrary = (provider): ethers.providers.Web3Provider => {
  const library = new ethers.providers.Web3Provider(provider);
  library.pollingInterval = POLLING_INTERVAL;
  return library;
};
