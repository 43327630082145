import { PageMeta } from "./types";

export const DEFAULT_META: PageMeta = {
  title: "BDS Invest",
};

export const customMeta: { [key: string]: PageMeta } = {
  "/": {
    title: "Home | BDS Invest",
  },
  "/competition": {
    title: "Trading Battle | BDS Invest",
  },
  "/prediction": {
    title: "Prediction | BDS Invest",
  },
  "/farms": {
    title: "Farms | BDS Invest",
  },
  "/pools": {
    title: "Pools | BDS Invest",
  },
  "/lottery": {
    title: "Lottery | BDS Invest",
  },
  "/collectibles": {
    title: "Collectibles | BDS Invest",
  },
  "/ifo": {
    title: "Initial Farm Offering | BDS Invest",
  },
  "/teams": {
    title: "Leaderboard | BDS Invest",
  },
  "/profile/tasks": {
    title: "Task Center | BDS Invest",
  },
  "/profile": {
    title: "Your Profile | BDS Invest",
  },
};
