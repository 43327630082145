import { MenuEntry } from "@bds-libs/uikit";

const config: MenuEntry[] = [
  {
    label: "Home",
    icon: "HomeIcon",
    href: "/",
  },
  {
    label: "Exchange",
    icon: "TradeIcon",
    href: "https://kaidex.io/exchange/0xab951b7c56682040DC62FFf35c8Bdb8fdEca8861",
  },
  {
    label: "Farms",
    icon: "FarmHouseIcon",
    href: "/farms",
  },
  {
    label: "Pools",
    icon: "PoolHouseIcon",
    href: "/pools",
  },
  {
    label: "Bridge",
    icon: "BridgeIcon",
    href: "https://bsc.bigbds.io/bridge",
    isNew: true,
  },
  {
    label: "Lottery",
    icon: "TicketIcon",
    href: "/lottery",
  },
  {
    label: "Referrals",
    icon: "ReferralIcon",
    href: "/referrals",
  },
  {
    label: "More",
    icon: "MoreIcon",
    items: [
      {
        label: "Docs",
        href: "https://docs.bigbds.io",
      },
      {
        label: "White Paper",
        href: "https://docs.bigbds.io/whitepaper",
      },
      {
        label: "Smart Contract",
        href: "https://explorer.kardiachain.io/token/0x72b7181bd4a0B67Ca7dF2c7778D8f70455DC735b",
      },
      {
        label: "Blog",
        href: "https://blog.bigbds.io/",
      },
      {
        label: "Helpdesk",
        href: "https://help.bigbds.io/hc/vi",
      },
    ],
  },
];

export default config;
