const tokens = {
  bnb: {
    symbol: "BNB",
    projectLink: "https://kardiachain.io/",
  },
  bds: {
    symbol: "BDS",
    address: {
      24: "0x72b7181bd4a0B67Ca7dF2c7778D8f70455DC735b",
      56: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
      97: "0x72b7181bd4a0B67Ca7dF2c7778D8f70455DC735b",
    },
    decimals: 8,
  },
  bdsLottery: {
    symbol: "BDS",
    address: {
      24: "0x72b7181bd4a0B67Ca7dF2c7778D8f70455DC735b",
      56: "0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82",
      97: "0x72b7181bd4a0B67Ca7dF2c7778D8f70455DC735b",
    },
    decimals: 8,
  },
  bdstest: {
    symbol: "BDS",
    address: {
      24: "0x7C0073F6285A89c7cf1e197e5D22554ff8726334",
      97: "0x72b7181bd4a0B67Ca7dF2c7778D8f70455DC735b",
    },
    decimals: 8,
  },
  viptest: {
    symbol: "VIP",
    address: {
      24: "0x7Ed49e5a6781F8082c94464896f66aC75435C176",
      97: "0x7Ed49e5a6781F8082c94464896f66aC75435C176",
    },
    decimals: 18,
  },

  vndc: {
    symbol: "VNDC",
    address: {
      24: "0xeFF34B63f55200a9D635B8ABBBFCC719b4977864",
      97: "0xeFF34B63f55200a9D635B8ABBBFCC719b4977864",
    },
    decimals: 0,
  },
  beco: {
    symbol: "BECO",
    address: {
      24: "0x2Eddba8b949048861d2272068A94792275A51658",
      97: "0x2Eddba8b949048861d2272068A94792275A51658",
    },
    decimals: 18,
    projectLink: "https://becoswap.com/",
  },
  wkai: {
    symbol: "WKAI",
    address: {
      24: "0xAF984E23EAA3E7967F3C5E007fbe397D8566D23d",
      97: "0xAF984E23EAA3E7967F3C5E007fbe397D8566D23d",
    },
    decimals: 18,
    projectLink: "https://kardiachain.io/",
  },

  kusd: {
    symbol: "KUSD",
    address: {
      24: "0x92364Ec610eFa050D296f1EEB131f2139FB8810e",
      97: "0x92364Ec610eFa050D296f1EEB131f2139FB8810e",
    },
    decimals: 6,
    projectLink: "https://kardiachain.io/",
  },

  dpet: {
    symbol: "DPET",
    address: {
      24: "0xfb62AE373acA027177D1c18Ee0862817f9080d08",
      97: "0xfb62AE373acA027177D1c18Ee0862817f9080d08",
    },
    decimals: 18,
    projectLink: "https://kardiachain.io/",
  },

  sen: {
    symbol: "SEN",
    address: {
      24: "0xb697231730C004110A86f51BfF4B8DD085c0CB28",
      97: "0xb697231730C004110A86f51BfF4B8DD085c0CB28",
    },
    decimals: 18,
    projectLink: "https://sleepearn.finance",
  },
  tph: {
    symbol: "TPH",
    address: {
      24: "0xc1c319434bd861A335752b4b6993C13f139B26fa",
      97: "0xc1c319434bd861A335752b4b6993C13f139B26fa",
    },
    decimals: 8,
    projectLink: "https://trustpay.vn",
  },
};

export default tokens;
