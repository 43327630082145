/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StatisticsState } from "../types";
import { getAddress } from "../../utils/addressHelpers";
import tokens from "../../config/constants/tokens";
import multicall from "../../utils/multicall";
import erc20 from "../../config/abi/erc20.json";
import { getBalanceNumber } from "../../utils/formatBalance";

const initialState: StatisticsState = {
  burned: 0,
  locked: 0,
  locked10: 0,
  distributed: 0,
  circulation: 0,
  users: 0,
  bep20: 0,
};

export const statisticsSlice = createSlice({
  name: "Block",
  initialState,
  reducers: {
    setStatistics: (state, action: PayloadAction<Partial<StatisticsState>>) => {
      return {
        ...initialState,
        ...state,
        ...action.payload,
      };
    },
  },
});

// Actions
export const { setStatistics } = statisticsSlice.actions;

export default statisticsSlice.reducer;

export const fetchStatisticAsync = () => async (dispatch) => {
  const calls = [
    // Balance of token in the LP contract
    {
      address: getAddress(tokens.bds.address),
      name: "balanceOf",
      params: ["0x7393cb99887df39bd17cfd32994efdf556c61f81"],
    },
    {
      address: getAddress(tokens.bds.address),
      name: "balanceOf",
      params: ["0x000000000000000000000000000000000000dEaD"],
    },
    {
      address: getAddress(tokens.bds.address),
      name: "balanceOf",
      params: ["0x081eb304C1dD3E88510A5043cbF828cAaeaCFe59"],
    },
    {
      address: getAddress(tokens.bds.address),
      name: "balanceOf",
      params: ["0x87E775c5A91A893a9167E8bb9c2e77B9fFE98f91"],
    },
    {
      address: getAddress(tokens.bds.address),
      name: "balanceOf",
      params: ["0xA58bf15D35a7F518b983D970cfDD6205DeEe522b"],
    },
    {
      address: getAddress(tokens.bds.address),
      name: "balanceOf",
      params: ["0x12f081f6a1fCB6756021F563C04093b0e8c7ff54"],
    },
    {
      address: getAddress(tokens.bds.address),
      name: "balanceOf",
      params: ["0x2D4746b6A1A9556a74F6d89B1277a2C058d1c5B5"],
    },
    {
      address: getAddress(tokens.bds.address),
      name: "balanceOf",
      params: ["0xc3626aEE59f009a04eC094399F8911a474e5E0A9"],
    },
    {
      address: getAddress(tokens.bds.address),
      name: "balanceOf",
      params: ["0x87AC7883Cad9511f301d6b85DF414A0720aBCd12"],
    },
  ];

  const response = await fetch(
    `https://backend.kardiachain.io/api/v1/krc20/0x72b7181bd4a0B67Ca7dF2c7778D8f70455DC735b/holders?page=1&limit=25`
  );

  const data = await response.json();

  const users = data.data.total;

  const r = await multicall(erc20, calls);

  const state = {
    locked10: getBalanceNumber(r[0], tokens.bds.decimals),
    burned: getBalanceNumber(r[1], tokens.bds.decimals),
    bep20: getBalanceNumber(r[8], tokens.bds.decimals),
  };

  const distributed = 1_000_000_000 - state.locked10 - state.burned;

  const locked =
    getBalanceNumber(r[2], tokens.bds.decimals) +
    getBalanceNumber(r[3], tokens.bds.decimals) +
    getBalanceNumber(r[4], tokens.bds.decimals) +
    getBalanceNumber(r[5], tokens.bds.decimals) +
    getBalanceNumber(r[6], tokens.bds.decimals) +
    getBalanceNumber(r[7], tokens.bds.decimals);

  dispatch(
    setStatistics({
      ...state,
      distributed,
      circulation: distributed - locked,
      locked,
      users,
    })
  );
};
