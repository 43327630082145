import tokens from "./tokens";
import { FarmConfig } from "./types";

const masterChefBDS = {
  97: "0x849FAFeE25c7F97B32c3Bd8C460aB95772aAC4c7",
  24: "0x849FAFeE25c7F97B32c3Bd8C460aB95772aAC4c7",
};

const masterChefKai = {
  97: "0x644CaE70e89D61b03144B185EE1f54dF36991a7E",
  24: "0x644CaE70e89D61b03144B185EE1f54dF36991a7E",
};

// @todo contract farm
const farms: FarmConfig[] = [
  {
    pid: 3,
    lpSymbol: "BDS",
    lpAddresses: {
      97: "0x72b7181bd4a0b67ca7df2c7778d8f70455dc735b",
      24: "0x72b7181bd4a0b67ca7df2c7778d8f70455dc735b",
    },
    token: tokens.bds,
    quoteToken: tokens.bds,
    masterChefContract: masterChefBDS,
    earnToken: tokens.bds,
    openAt: 1627783200000,
    beforeOpenPerBlock: "5787037.037037037",
  },
  {
    pid: 1,
    lpSymbol: "BDS",
    lpAddresses: {
      97: "0x72b7181bd4a0B67Ca7dF2c7778D8f70455DC735b",
      24: "0x72b7181bd4a0B67Ca7dF2c7778D8f70455DC735b",
    },
    token: tokens.bds,
    quoteToken: tokens.bds,
    masterChefContract: masterChefKai,
    earnToken: tokens.wkai,
    openAt: 1627783200000,
    beforeOpenPerBlock: "212210648148148000",
  },
  {
    pid: 1,
    lpSymbol: "BDS-KUSDT LP",
    lpAddresses: {
      97: "0x3737a7362a6d5bdd39573f3844ca704b61a8a06e",
      24: "0x3737a7362a6d5bdd39573f3844ca704b61a8a06e",
    },
    token: tokens.bds,
    quoteToken: tokens.kusd,
    masterChefContract: masterChefBDS,
    earnToken: tokens.bds,
    openAt: 1627783200000,
    beforeOpenPerBlock: "5787037.037037037",
  },
  {
    pid: 0,
    lpSymbol: "BDS-KAI LP",
    lpAddresses: {
      97: "0xab951b7c56682040dc62fff35c8bdb8fdeca8861",
      24: "0xab951b7c56682040dc62fff35c8bdb8fdeca8861",
    },
    token: tokens.bds,
    quoteToken: tokens.wkai,
    masterChefContract: masterChefBDS,
    earnToken: tokens.bds,
    openAt: 1627783200000,
    beforeOpenPerBlock: "5787037.037037037",
  },
  {
    pid: 2,
    lpSymbol: "BDS-VNDC LP",
    lpAddresses: {
      97: "0x909eb13a77be16fd6d4a763b701b3aa82a5aff9d",
      24: "0x909eb13a77be16fd6d4a763b701b3aa82a5aff9d",
    },
    token: tokens.bds,
    quoteToken: tokens.vndc,
    masterChefContract: masterChefBDS,
    earnToken: tokens.bds,
    openAt: 1627783200000,
    beforeOpenPerBlock: "5787037.037037037",
  },
];

export default farms;
